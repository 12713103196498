
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {ICoachByPage} from "@/apis/finance/coachReconcile/types";
import {lastMonthVideoCoursesByPageApi, queryLastMonthCommissionByPageApi} from "@/apis/finance/coachReconcile";
import {deepCopy} from "@/utils/common";

/**
 * @name: coachReconcileUltimo
 * @author: Gzm
 * @date: 2023-06-01 11:10
 * @description：财务结算-教练佣金对账-发放上月佣金
 * @update: 2023-06-01 11:10
 */
@Component({})
export default class ProductBasic extends Vue {
  @Prop(Boolean) dialogVisible!: boolean;
  @Prop(Object) data!: object;

  queryForm: any = {
    page: 1,
    limit: 10
  }

  queryFormVideo: any = {
    page: 1,
    limit: 10
  }
  total: number = 0

  tableData: ICoachByPage[] = []
  totalVideo: number = 0
  tableDataVideo: ICoachByPage[] = []
  lastMonthTotal: any = null
  lastMonthTotalVideo: any = null
  activeName = 'first'
  ultimoData: any = {}

  /***
   * crudOption
   * 页面配置对象
   */
  crudOption: any = {
    searchBox: true,
    menu: false,
    column: [
      {
        label: "订单编号",
        prop: "orderSn",
        align: 'center',
        width: 200,
      },
      {
        label: "课程",
        prop: "courseName",
        align: 'center',
      },
      {
        label: "卡券单价",
        prop: "price",
        align: 'center',
      },
      {
        label: "下单客户",
        prop: "orderCustom",
        align: 'center',

      },
      {
        label: "下单客户",
        prop: "userName",
        align: 'center',
        search: true,
        hide: true
      },
      {
        label: "佣金",
        prop: "commission",
        align: 'center',
      },
      {
        label: "使用时间",
        prop: "writeOffTime",
        align: 'center',
      },
      {
        label: "发放状态",
        prop: "commissionStatus",
        align: 'center',
        value: 2,
        type: "switch",//状态:1=有效 2=无效
        dicData: [{label: '未发放', value: 1}, {label: '已发放', value: 2},],
      },
      {
        label: "订单编号",
        prop: "orderSn",
        align: 'center',
        search: true,
        hide: true
      },
    ]
  }
  crudOptionVideo: any = {
    searchBox: true,
    menu: false,
    column: [
      {
        label: "订单编号",
        prop: "orderSn",
        align: 'center',
        width: 200,
      },
      {
        label: "视频课程名称",
        prop: "courseName",
        align: 'center',
      },
      {
        label: "视频课程单价",
        prop: "price",
        align: 'center',
      },
      {
        label: "下单客户",
        prop: "orderCustom",
        align: 'center',

      },
      {
        label: "下单客户",
        prop: "userName",
        align: 'center',
        search: true,
        hide: true
      },
      {
        label: "佣金",
        prop: "commission",
        align: 'center',
      },
      {
        label: "发放状态",
        prop: "commissionStatus",
        align: 'center',
        value: 2,
        type: "switch",//状态:1=有效 2=无效
        dicData: [{label: '未发放', value: 1}, {label: '已发放', value: 2},],
      },
      {
        label: "订单编号",
        prop: "orderSn",
        align: 'center',
        search: true,
        hide: true
      },
    ]
  }
  selectRow: any = []

  /**
   * 获取数据
   */
  getList() {
    queryLastMonthCommissionByPageApi(this.queryForm).then(e => {
      //@ts-ignore
      this.tableData = e.singleOrderDtlVoPage.records;
      //@ts-ignore
      this.lastMonthTotal = e.lastMonthTotal
      //@ts-ignore
      this.total = e.singleOrderDtlVoPage.total
    })
  }

  getListVideo() {
    lastMonthVideoCoursesByPageApi(this.queryFormVideo).then(e => {
      this.tableDataVideo = e.list;
      this.totalVideo = e.total
    })
  }

  get computedData() {
    return this.data;
  }

  @Watch('computedData')
  onChangeValue(newVal: any, oldVal: any) {
    this.queryForm.id = newVal.id
    this.queryFormVideo.id = newVal.id
    this.activeName = "first"
    this.getList()
  }

  handleClick(e: any) {
    console.log(e)
    if (e.index == 0) {
      this.getList()
    } else {
      this.getListVideo()
    }
  }

  handleCancel(val: boolean) {
    this.$emit('Change', val)
  }
}
