
/**
 * @name: channelLook
 * @author: Gzm
 * @date: 2023-06-02 15:10
 * @description：财务结算-渠道方佣金对账-查看佣金
 * @update: 2023-06-02 15:10
 */
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {ICoachByPage} from "@/apis/finance/coachReconcile/types";
import {
  queryChannelCommissionByPageApi,
  queryCoachCommissionByPagePageApi,
  queryLastMonthCommissionByPageApi
} from "@/apis/finance/coachReconcile";
import {deepCopy} from "@/utils/common";
import Qrcode from "qrcodejs2";

@Component({})
export default class ProductBasic extends Vue {
  @Prop(Boolean) dialogVisible!: boolean;
  @Prop(Object) data!: object;
  dialogCode: boolean = false
  queryForm: any = {
    page: 1,
    limit: 10,
    //1=普通用户 2=达人 3=教练 4=渠道方
    userTypes:4
  }
  total: number = 0

  tableData: ICoachByPage[] = []

  ultimoData: any = {}

  /***
   * crudOption
   * 页面配置对象
   */
  crudOption: any = {
    searchBox: true,
    menu: false,
    column: [
      {
        label: "订单编号",
        prop: "orderSn",
        align: 'center',
        width: 200,
      },
      {
        label: "订单类型",
        prop: "orderTypes",
        align: 'center',
        value: 2,
        type: "switch",
        dicData: [{label: '商城订单', value: 1}, {label: '旅游订单', value: 2},{label: '赛事订单', value: 3}, {label: '1v1课程订单', value: 4},{label: '网红订单', value: 5}, {label: '视频课程订单', value: 6}],
      },
      {
        label: "价格",
        prop: "payPrice",
        align: 'center',
      },
      {
        label: "下单客户",
        prop: "userName",
        align: 'center',
      },
      {
        label: "佣金",
        prop: "userCommission",
        align: 'center',
      },
      {
        label: "支付方式",
        prop: "payWay",
        align: 'center',
        value: 2,
        type: "switch",
        dicData: [{label: '余额', value: 1}, {label: '微信支付', value: 2}],
      },
      {
        "label": "订单时间",
        "prop": "payTime",
        "align": "center",
        "width": 150,
        "type": "daterange",
        "search": true,
      },
      {
        label: "订单类型",
        prop: "orderTypes",
        align: 'center',
        value: 2,
        type: "switch",
        search: true,
        hide: true,
        dicData: [
          {label: '商城订单', value: 1},
          {label: '旅游订单', value: 2},
          {label: '赛事订单', value: 3},
          {label: '1v1课程订单', value: 4},
          {label: '网红订单', value: 5},
          {label: '视频课程订单', value: 6}],
      },
      {
        label: "支付方式",
        prop: "payWay",
        align: 'center',
        value: 2,
        type: "switch",
        dicData: [{label: '余额', value: 1}, {label: '微信支付', value: 2}],
        search: true,
        hide: true
      },
      {
        label: "订单编号",
        prop: "orderSn",
        align: 'center',
        search: true,
        hide: true
      },
    ]
  }
  dialogCodeData: any = {}

  /**
   * 获取数据
   */
  getList() {
    const query: any = deepCopy(this.queryForm)
    if (this.queryForm.payTime && this.queryForm.payTime.length === 2) {
      query.payTimeStart = this.queryForm.payTime[0]
      query.payTimeEnd = this.queryForm.payTime[1]
    } else {
      query.payTimeStart = ''
      query.payTimeEnd = ''
    }
    delete query.payTime
    queryChannelCommissionByPageApi(query).then(e => {
      this.tableData = e.list;
      this.total = e.total
    })
  }

  get computedData() {
    return this.data;
  }

  @Watch('computedData')
  onChangeValue(newVal: any, oldVal: any) {
    console.log(newVal)
    this.queryForm.userId =newVal.id
    this.getList()
  }

  handleCancel(val: boolean) {
    this.$emit('Change', val)
  }

}
