
/**
 * @name: channel
 * @author: Gzm
 * @date: 2023-05-30 10:27
 * @description：财务结算-渠道方佣金对账
 * @update: 2023-05-30 10:27
 */
import {Component, Vue} from "vue-property-decorator";
import {Message} from "element-ui";
import {
  channelSettlementExportApi,
  coachSettlementBatchGrantApi, queryChannelSettlementByPageApi,
} from "@/apis/finance/coachReconcile";
import {ICoachByPage} from "@/apis/finance/coachReconcile/types";
import CoachReconcileUltimo from "@/views/finance/components/coachReconcileUltimo.vue";
import CoachReconcileLook from "@/views/finance/components/coachReconcileLook.vue";
import ChannelLook from "@/views/finance/components/channelLook.vue";

@Component({
  components: {ChannelLook, CoachReconcileLook, CoachReconcileUltimo}
})
export default class UserIndex extends Vue {

  queryForm: any = {
    page: 1,
    limit: 10,
    //1=普通用户 2=达人 3=教练 4=渠道方
    userTypes:4
  }
  total: number = 0
  // 弹窗开关
  dialogVisible = false
  dialogVisibleLook = false

  tableData: ICoachByPage[] = []

  ultimoData:any = {}
  lookData:any = {}

  /***
   * crudOption
   * 页面配置对象
   */
  crudOption: any = {
    searchBox: false,
    menu: false,
    column: [
      {
        label: "",
        type: "selection",
        width: 50,
        align: "center",
        viewHide: true
      },
      {
        label: "ID",
        prop: "id",
        align: 'center',
        width: 200,
      },
      {
        label: "渠道方名称",
        prop: "userName",
        align: 'center',
      },
      {
        label: "渠道方账号",
        prop: "userName",
        align: 'center',
      },
      {
        label: "手机号",
        prop: "phone",
        align: 'center',
      },
      {
        label: "上月获客",
        prop: "lastMonthCustomNumber",
        align: 'center',
      },
      {
        label: "上月客户消费",
        prop: "lastMonthConsumeTotal",
        align: 'center',
      },
      {
        label: "上月客户消费商品",
        prop: "lastMonthConsumeNumber",
        align: 'center',
      },
      {
        label: "上月累计佣金",
        prop: "lastMonthCommission",
        align: 'center',
      },
      // {
      //   label: "状态",
      //   prop: "abilityStatus",
      //   align: 'center',
      //   value: 2,
      //   type: "switch",//状态:1=有效 2=无效
      //   dicData: [{label: '启用', value: 1}, {label: '禁用', value: 2}],
      // },
      {
        label: "操作",
        prop: "operate",
        align: 'center',
        slot: true
      },
    ]
  }
  selectRow: any = []

  get selectIds() {
    return this.selectRow.map((item: any) => item.id)
  }

  handleBatch() {
    if (this.selectIds.length == 0) {
      return this.$message.error("请选择数据")
    }
    this.handleSettle(this.selectIds)
  }

  handleSettle(ids: string[]) {
    if (!ids || ids.length == 0) {
      return
    }
    let idList = ids.join(',')
    console.log(idList,'ids')
    channelSettlementExportApi(idList).then(e => {
      const url = window.URL.createObjectURL(new Blob([e]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', '导出渠道方佣金对账.xlsx'); // Set the file name and extension
      document.body.appendChild(link);
      link.click();
    })
  }



  /**
   * 获取数据
   */
  getList() {
    queryChannelSettlementByPageApi(this.queryForm).then(e => {
      this.tableData = e.list;
      this.total = e.total
    })
  }

  /**
   * 打开弹窗-查看佣金
   */
  async openLook(row:any) {
    this.lookData = row
    this.dialogVisibleLook = true
  }


  handleLook(e:any){
    this.dialogVisibleLook = e
  }

  created() {
    this.getList();
  }
}
